import React from 'react'
import { Grid, Cell } from '@trbl/react-css-grid';

const BTFTOS = React.memo(({body}) => {
  return (
    <section>
      <Grid>
        <Cell
          hStart={6}
          hSpan={10}
          hStartM={5}
          hSpanM={11}
          hStartS={2}
          hSpanS={14}
        >
          {body && (
            <div
              className="wmfab__markup-content small-print"
              dangerouslySetInnerHTML={{ __html: body }}
            />
          )}
        </Cell>
      </Grid>
    </section>
  );
});

export default BTFTOS;
